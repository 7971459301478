
import './icon.scss';

const IconUA = () => {
  return (
    <div className="icon icon-UA">
      <p className='icon_p'>
        UA
      </p>
    </div>
  );
};

export default IconUA;


import './icon.scss';

const IconPL = () => {
  return (
    <div className="icon icon-PL">
      <p className='icon_p'>
        PL
      </p>
    </div>
  );
};

export default IconPL;

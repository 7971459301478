// import { useEffect } from 'react';

import { Outlet } from 'react-router-dom';

const LayoutPage = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default LayoutPage;
